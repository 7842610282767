import React from 'react';
import { Accordion, Container } from 'react-bootstrap';

const Faq = () => {
  const faqs = [
    // General
    {
      section: "General",
      question: "What is Auto Nomad?",
      answer: "Auto Nomad is an application that allows real-time audio-to-audio translation. It is ideal for multilingual conversations without barriers."
    },
    {
      section: "General",
      question: "What is Vision Nomad?",
      answer: "Vision Nomad is an application that translates images. You can capture an image with text and get an instant translation."
    },
    {
      section: "General",
      question: "What is Manual Nomad?",
      answer: "Manual Nomad is an application that allows you to record audios, translate them, and transcribe them. It is perfect for taking notes or recording conversations in different languages."
    },
    // Subscriptions
    {
      section: "Subscriptions",
      question: "What is included in the Explorer subscription?",
      answer: "The Explorer subscription is free and designed for curious and new users in the world of translations. It offers limited functionalities for a first contact with our services."
    },
    {
      section: "Subscriptions",
      question: "What is included in the Pioneer subscription?",
      answer: "The Pioneer subscription costs USD 20 per month or USD 200 per year. It offers 50 translation points, access to the most demanded languages, text-to-speech and speech-to-speech capabilities, and reliable technical support."
    },
    {
      section: "Subscriptions",
      question: "What is included in the Nomad subscription?",
      answer: "The Nomad subscription costs USD 40 per month or USD 400 per year. It offers 100 translation points, access to all available languages, high-quality voice translations, exclusive features for global travelers, and premium technical support."
    },
    {
      section: "Subscriptions",
      question: "Can I change my subscription plan at any time?",
      answer: "Yes, you can change your subscription plan at any time from the account settings."
    },
    {
      section: "Subscriptions",
      question: "What payment options are available?",
      answer: "We offer pay as you go, which can be paid via PayPal."
    },
    // Functionalities
    {
      section: "Functionalities",
      question: "How many languages does the application support?",
      answer: "The application supports a wide range of languages for recognition and translation of voice and text:\n- Recognized languages: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, Welsh.\n- Spoken languages: English, Spanish, Portuguese, French, Japanese, German, Chinese, Russian, Arabic, Hindi, Swedish, Italian, Afrikaans, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Croatian, Czech, Danish, Dutch."
    },
    {
      section: "Functionalities",
      question: "How does the audio-to-audio translation work?",
      answer: "Audio-to-audio translation uses artificial intelligence to recognize and translate spoken language in real-time, reproducing the translation in the selected language. Points are deducted based on the length of the audio, the translated text, and the text-to-speech translation."
    },
    {
      section: "Functionalities",
      question: "Can I save my translated audios?",
      answer: "Yes, you can save your translated audios, depending on your subscription type."
    },
    {
      section: "Functionalities",
      question: "What is a translation point?",
      answer: "A translation point is a unit used to measure the usage of translation functionalities. Each translation consumes a certain number of points based on the length of the audio, the translated text, and the text-to-speech translation."
    },
    {
      section: "Functionalities",
      question: "How can I check my remaining points?",
      answer: "You can check your remaining points in the user panel of the application."
    },
    {
      section: "Functionalities",
      question: "Does the application work offline?",
      answer: "No, the application requires an internet connection for all its functionalities."
    },
    {
      section: "Functionalities",
      question: "On which devices can I use the application?",
      answer: "The application is used through a web browser, so it is compatible with any device that has access to an updated browser."
    },
    {
      section: "Functionalities",
      question: "Do I need to accept camera and microphone permissions?",
      answer: "Yes, to use the audio and image translation functionalities, you need to accept camera and microphone permissions."
    },
    {
      section: "Functionalities",
      question: "How many types of voice speakers are available?",
      answer: "The application offers 6 different types of voice speakers to customize the audio output according to your preferences."
    },
    // Support and Help
    {
      section: "Support and Help",
      question: "How can I contact technical support?",
      answer: "You can contact technical support through the contact form in our app or by emailing martin@moreirawebdesign.com."
    },
    {
      section: "Support and Help",
      question: "What type of support is available for each plan?",
      answer: "Explorer plan users have access to basic support, while Pioneer and Nomad plan users have access to reliable and premium technical support, respectively."
    },
    // Security and Privacy
    {
      section: "Security and Privacy",
      question: "How is my personal data handled?",
      answer: "We take your data privacy and security very seriously. You can read our Privacy Policy for more details on how we handle your data."
    },
    {
      section: "Security and Privacy",
      question: "Are my audios and translations private?",
      answer: "Yes, all your audios and translations are private and only accessible by you. We do not share your data with third parties."
    },
    {
      section: "Security and Privacy",
      question: "What security measures do you use to protect my data?",
      answer: "We implement advanced security measures, using databases provided by MongoDB and Firebase, known for their robust security protocols, to ensure the protection of your personal information."
    },
    {
      section: "Security and Privacy",
      question: "Do you use cookies or tracking technologies?",
      answer: "No, Nomad operates without the use of cookies or tracking technologies, reaffirming our commitment to user privacy."
    },
    {
      section: "Security and Privacy",
      question: "How can I register for the application?",
      answer: "You can register for the application using your Google account or by entering your personal information, such as your name, surname, and email address."
    },
    {
      section: "Security and Privacy",
      question: "Can I edit my personal information?",
      answer: "Currently, we do not offer a direct function to edit your information, but we are working on it. Meanwhile, you can request access, correction, or deletion of your information by contacting our support team."
    },
    {
      section: "Security and Privacy",
      question: "What happens if the privacy policy is updated?",
      answer: "We reserve the right to modify this privacy policy. Significant changes will be communicated via registered email, ensuring you are always informed about how we protect your information."
    }
  ];

  const renderFaqsBySection = (section) => {
    return faqs
      .filter(faq => faq.section === section)
      .map((faq, index) => (
        <Accordion.Item eventKey={`${section}-${index}`} key={`${section}-${index}`}>
          <Accordion.Header>{faq.question}</Accordion.Header>
          <Accordion.Body>{faq.answer}</Accordion.Body>
        </Accordion.Item>
      ));
  };

  return (
    <Container className="mt-4 mb-4">
      <div className='faqintro'>
        <h1>FAQs</h1>
        <h3>Welcome to the Nomad Frequently Asked Questions page! Here, you'll find answers to common questions about our translation app, including how to get started, the languages we support, and tips for making the most of our features. If you need further assistance, feel free to reach out to our support team.</h3>
      </div>

      <div className='accordion-faq'>
      <Accordion defaultActiveKey="0">
        <h2>General</h2>
        {renderFaqsBySection("General")}
        <h2>Subscriptions</h2>
        {renderFaqsBySection("Subscriptions")}
        <h2>Functionalities</h2>
        {renderFaqsBySection("Functionalities")}
        <h2>Support and Help</h2>
        {renderFaqsBySection("Support and Help")}
        <h2>Security and Privacy</h2>
        {renderFaqsBySection("Security and Privacy")}
      </Accordion>
      </div>
      <div className='faqfooter'>
        <h1>If you have any other question</h1>
        <p>you can contact us on: <a href='mailto:martin@moreirawebdesing.com'>martin@moreirawebdesing.com</a></p>
      </div>
    </Container>
  );
};

export default Faq;
