import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <footer>
                <div className="footer">
                    <div className="footer-left">
                        <Link to="https://www.moreirawebdesign.com/">Developed with AI by <strong>MOREIRA dev </strong></Link>
                        <img src='/images/logomm.png' alt="Moreira dev " width="25" />
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
