import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';


const Suscriptions = () => {


    return (
        <Container className='suscriptioncontainer'>
            <div className='titulo'>
                <Row>
                    <h2 className="my-4 mb-0">Choose the best for you</h2>
                    <p>Pay as you GO!</p>
                </Row>
            </div>
            <Row>
                {/* Ejemplo para el plan Explorer */}
                <Col md={4} className="cardsuscription">
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <img className="suscr-img" src="/images/binoculares-w.png" alt="Explorer" />
                                <h3>Explorer (Free)</h3>
                            </Card.Title>
                            <Card.Text>
                                Ideal for the curious and new adventurers in the world of translations. Enjoy limited functionalities, perfect for having a first contact with our services.
                                <br />
                                <strong>Price:</strong> Free
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Ejemplo para el plan Pioneer */}
                <Col md={4} className="cardsuscription">
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <img className="suscr-img" src="/images/brujula-w.png" alt="Pioneer" />
                                <h3>Pioneer: USD 5</h3>
                            </Card.Title>
                            <Card.Text>
                                Designed for users seeking a more complete experience in their travels and everyday adventures. This plan offers 50 points for translations, access to the most in-demand languages, translations with text-to-speech and speech-to-speech capabilities, and you will also have reliable technical support.
                                <br />
                                <strong>Price:</strong> USD 5
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Ejemplo para el plan Nomad */}
                <Col md={4} className="cardsuscription">
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <img className="suscr-img" src="/images/excursionismo-w.png" alt="Nomad" />
                                <h3>Nomad: USD 10</h3>
                            </Card.Title>
                            <Card.Text>
                                The ideal plan for global travelers and those in constant motion. It offers 100 points, access to all available languages, translations with high-quality voice, exclusive features tailored for the global traveler, and premium technical support. The definitive option for those who require unlimited translation services.
                                <br />
                                <strong>Price:</strong> USD 10
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Suscriptions;
